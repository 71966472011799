module.exports={
  "name": "@uppy/aws-s3-multipart",
  "description": "Upload to Amazon S3 with Uppy and S3's Multipart upload strategy",
  "version": "1.8.2",
  "license": "MIT",
  "main": "lib/index.js",
  "types": "types/index.d.ts",
  "keywords": [
    "file uploader",
    "aws s3",
    "amazon s3",
    "s3",
    "uppy",
    "uppy-plugin",
    "multipart"
  ],
  "homepage": "https://uppy.io",
  "bugs": {
    "url": "https://github.com/transloadit/uppy/issues"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/transloadit/uppy.git"
  },
  "dependencies": {
    "@uppy/companion-client": "file:../companion-client",
    "@uppy/utils": "file:../utils"
  },
  "peerDependencies": {
    "@uppy/core": "^1.0.0"
  }
}
